<template>
  <div class="p-30 protocol">
    <back></back>
    <div class="f-17 bold text-center">轻松小镇网站用户协议</div>
    <div>
      欢迎您使用轻松小镇网站及服务！
      <br>为使用轻松小镇网站（以下统称“本网站”）及服务（以下统称“本服务”），您应当
      阅读并遵守《轻松小镇网站许可及服务协议》（以下简称“本协议”）以及《轻松小镇
      用户隐私协议》。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制轻松
      元宇责任的条款、对用户权利进行限制的条款、约定争议解决方式和司法管辖的条款等，
      以及开通或使用某项服务的单独协议。限制、免责条款或者其他涉及您重大权益的条款
      可能以加粗、加下划线等形式提示您重点注意。如果您对本协议内容或页面提示信息有
      任何疑问，请先联系客服咨询。
      <br>除非您已充分阅读、完全理解并接受本协议所有条款，否则您无权使用本网站和/或相
      关服务。您点击“同意”或您的使用、登录等行为或者您以其他任何明示或者默示方式
      表示接受本协议的，即视为您已阅读并同意本协议的约束。本协议即在您与轻松元宇之
      间产生法律效力，成为对双方均具有约束力的法律文件。
      任何未满18周岁的未成年人均不得注册帐号或使用本服务。轻松元宇公司将依赖您提供
      的个人信息判断用户是否为未成年人。此外，如果您不具有完全民事行为能力，请在法
      定监护人（以下简称“监护人”）的陪同下阅读和判断是否同意本协议。
      <br>一、【协议的范围】
      <br>1.1本协议是您与深圳市轻松元宇科技有限公司（下称“轻松元宇”或“我们”）之间
      关于您登录本网站，以及使用本服务所订立的协议。
      <br>1.2本协议的内容，包括但不限于以下与本网站和/或本服务相关的协议、规则、规范
      以及轻松元宇可能不断发布的关于本网站和/或本服务的相关协议、规则、规范等内容。
      前述内容一经正式发布，即为本协议不可分割的组成部分，与其构成统一整体，您同样
      应当遵守：《轻松小镇用户隐私协议》。
      <br>1.3轻松元宇作为本网站的运营主体以及服务提供方，有权不经另行通知变更网站运营
      和/或服务提供主体。变更后的主体将继续按照本协议约定并向您提供服务，上述主体
      的变更不会影响您本协议项下的权益。
      <br>二、【本服务说明】
      <br>2.1轻松元宇在自有服务基础上，采用腾讯至信链的技术服务（https://www.zxinchain.com/），
      通过本网站向您提供访问、分享、购买数字道
      具（下称“道具”）/数字藏品（下称“藏品”）的入口。道具为轻松小镇内部发行的
      相关使用道具，用户可用于参与小镇内部互动。藏品具有加密性和不可篡改性，即藏品
      元数据中的创作者、创作和上链时间、购买者等信息，一经确认则不可篡改。您购买藏
      品后，作为购买者，您的相关信息将写入该藏品的元数据中，作为您拥有该藏品所有权
      的凭证。
      <br>2.2本网站中道具/藏品可通过参与抽签免费获得或付费购买获得。如需购买道具/藏品，
      您需要支付一定费用，具体收费标准以页面实际展示为准。您应该通过轻松元宇指定的
      现有方式包括但不限于微信支付以及各网银支付等或今后轻松元宇指定的方式支付费
      用。支付完成后，除法定情形外，不可进行转让或要求退费。
      <br>2.3您成功购买道具/藏品后，本网站将会提供道具/藏品展示的服务，供您进行学习、
      研究、欣赏、收藏。除上述使用目的外，您不得用于任何其他目的。
      <br>2.4您知晓并同意，鉴于网络服务的特殊性，如出现技术升级、服务体系升级、经营策
      略调整或配合国家重大技术、法律法规、政策等变化，轻松元宇可能会根据需要更新或
      调整本网站和/或本服务的内容，甚至中止、终止向您提供全部或部分服务。上述行为
      不视为轻松元宇违约，您无权要求轻松元宇承担任何责任。
      <br>三、【关于本网站】
      <br>3.1本网站是指轻松元宇或其关联公司开发并按照本协议之约定，授权用户登录、使用
      的网站。同时，轻松元宇会不断丰富您使用本服务的终端、形式等。当您使用本服务时，
      您应选择与您的终端、系统等相匹配的本网站版本，否则，您可能无法正常使用本服务。
      3.2轻松元宇给予您一项个人的、不可转让及非排他性的许可，以按照本协议及轻松元
      <br>宇其他协议、规则限定的范围和方式使用本网站及相关服务。您且仅限于您个人，可为
      非商业目的使用。
      <br>3.2.1您可以为非商业目的在单一台终端设备上使用、登录本网站。
      <br>3.2.2本协议未明示授权的其他一切权利仍由轻松元宇保留，您在行使这些权利时须另外
      取得轻松元宇的书面许可。轻松元宇如果未行使前述任何权利，并不构成对该权利的放
      弃。
      <br>3.3为了增进用户体验、完善服务内容，轻松元宇可能不断努力开发新的服务，并为您
      不时提供网站更新。
      <br>3.4为了改善用户体验或提高服务安全性、保证功能的一致性等目的，轻松元宇有权对
      本网站进行更新，或者对本网站的部分功能效果、服务内容进行改变。
      <br>3.5本网站新版本发布后，旧版本的网站可能无法使用。轻松元宇不保证旧版本网站继
      续可用，也不保证继续对旧版本网站提供相应的客服和维护服务，请您随时核对并下载
      最新版本。
      <br>四、【服务帐号】
      <br>4.1您在使用本服务前需要使用手机号码注册帐号（以下或称“帐号”），并根据验证
      码登陆。轻松元宇有权变更本网站帐号申请、使用、找回的规则，具体以轻松元宇公布
      为准。
      <br>4.2您在注册帐号或使用本服务的过程中，可能需要填写一些必要的信息。若国家法律
      法规（本协议中的“法律法规”指用户所属/所处地区、国家现行有效的法律、行政法
      规、司法解释、地方法规、地方规章、部门规章及其他规范性文件以及对于该等法律法
      规的不时修改和补充，以及相关政策规定等，下同）有特殊规定的，您需要填写真实、
      准确、合法、有效的身份信息（包括但不限于手机号码、身份证件等信息）。若您填写
      的信息不真实、不准确、不合法、不规范，我们有理由怀疑为错误、不实或不合法的资
      料，则我们有权拒绝为您提供相关服务或您可能无法使用本网站及相关服务或在使用过
      程中部分功能受到限制。如果您提供的注册资料不合法、不真实、不准确、不详尽的，
      您需承担因此引起的相应责任及后果，并且轻松元宇保留终止您使用本服务的权利。
      <br>4.3用户有义务妥善保管其帐号及验证码，并正确、安全地使用其帐号及验证码，并对
      您以服务帐号名义所从事的活动承担全部法律责任，包括但不限于您在本网站进行的任
      何言论发表、购买、款项支付（如有）等操作行为。用户未尽上述义务导致帐号密码遗
      失、帐号被盗等情形而给用户和他人的民事权利造成损害的，应当自行承担由此产生的
      法律责任。
      <br>4.4服务帐号的所有权归轻松元宇公司所有，用户完成申请、注册手续后，仅获得该帐号的使用权，且该使用权仅属于初始申请注册人。同时，初始申请注册人不得赠与、借
      用、租用、转让或售卖帐号或者以其他方式许可非初始申请注册人使用帐号。非初始申
      请注册人不得通过受赠、承租、受让或者其他任何方式使用帐号。
      <br>4.5您理解并承诺，您所设置的帐号不得违反国家法律法规及本网站使用的相关规则。得账户使用权，但相关限制可在后续使用方式上进行探讨
      您设置的帐号名称、头像等信息及其他个人信息中不得出现违法和不良信息，未经他人
      许可不得用他人名义（包括但不限于冒用他人姓名、名称、字号、头像等足以让人引起
      混淆的方式）开设帐号。您在帐号注册及使用过程中需遵守相关法律法规，不得实施任磅
      何侵害国家利益、损害其他公民合法权益或有害社会道德风尚的行为。
      <br>4.6如需注销帐号，您应审慎阅读并同意《帐号注销协议》，方可提交注销申请。您仅
      能申请注销您本人的帐号，并在本网站内按照要求的流程进行注销。注销成功后，帐号
      涉及的个人信息、使用记录等将无法恢复或提供。
      <br>五、【用户个人信息保护】
      <br>5.1保护用户个人信息是轻松元宇的一项基本原则，轻松元宇将按照本协议及《轻松小
      镇用户隐私协议》的约定收集、使用、储存和分享您的个人信息。本协议对个人信息保
      护相关内容未作明确规定的，均应以《轻松小镇用户隐私协议》的内容为准。
      <br>5.2轻松元宇不会将您的个人信息转移或披露给任何第三方，除非：
      <br>（1）相关法律法规或司法机关、行政机关要求
      <br>（2）为完成合并、分立、收购或资产转让而转移
      <br>（3）为提供您要求的服务所必需
      <br>（4）依据《轻松小镇用户隐私协议》或其他相关协议规则可以转移或披露给任何第三
      方的情形。
      <br>5.3为了更好地向您提供本服务，在获得您的明确同意后，轻松元宇可对您服务帐号中
      的昵称、头像以及在本网站中的相关操作信息（以下称“该等信息”）进行使用，并可
      在本网站中向您本人或其他用户展示该等信息。
      <br>5.4您在使用本服务某一特定功能时，轻松元宇在获得您明确同意的前提下可以使用您
      终端设备的相关权限、接口及相关信息等以实现相应的功能。您可以选择不向轻松元宇
      提供您的相关权限、接口、信息，或者根据产品设置取消轻松元宇使用相关权限、接口
      或收集某些信息的权利，但因此可能会导致相关服务功能无法实现。
      <br>5.5您应对通过本网站和/或本服务了解、接收或可接触到的包括但不限于其他用户在
      内的任何人的个人信息予以充分尊重，您不应以搜集、复制、存储、传播或以其他任何
      方式使用其他用户的个人信息，否则，由此产生的后果由您自行承担。
      <br>六、【用户行为规范】
      <br>6.1【用户禁止行为】
      您在使用本网站和/或本服务的过程中，应遵守相关法律法规、本协议、规则、规范等，
      不得从事包括但不限于以下任何行为，也不得为以下任何行为提供便利：
      <br>6.1.1法律法规禁止的行为
      您在使用本服务时须遵守法律法规，不得制作、复制、发布、传播含有下列内容的信息
      或从事相关行为，也不得为制作、复制、发布、传播含有下列内容的信息或从事相关行
      为提供便利：
      <br>（1）反对宪法所确定的基本原则的；
      <br>（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
      <br>（3）损害国家荣誉和利益的；
      <br>（4）煽动民族仇恨、民族歧视，破坏民族团结的；
      <br>（5）破坏国家宗教政策，宣扬邪教和封建迷信的；
      <br>（6）散布谣言，扰乱社会秩序，破坏社会稳定的；
      <br>（7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
      <br>（8）侮辱或者诽谤他人，侵害他人合法权益的；
      <br>（9）违反法律法规底线、社会主义制度底线、国家利益底线、公民合法权益底线、社
      会公共秩序底线、道德风尚底线和信息真实性底线的“七条底线”要求的；
      <br>（10）法律法规、本协议或使用本网站的其他规则等禁止的其他行为。
      <br>6.1.2网站使用
      <br>除非法律法规允许或轻松元宇书面许可，您不得从事下列行为：
      <br>（1）删除本网站及其副本上关于著作权的信息；
      <br>（2）对本网站进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现本网站
      的源代码；
      <br>（3）对轻松元宇拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转
      载、汇编、发表、出版、建立镜像站点等；
      <br>（4）对本网站或者本网站运行过程中释放到任何终端内存中的数据、网站运行过程中
      客户端与服务器端的交互数据，以及本网站运行所必需的系统数据，进行复制、修改、
      增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非轻
      松元宇经授权的第三方工具/服务接入本网站和相关系统；
      <br>（5）通过修改或伪造网站运行中的指令、数据，增加、删减、变动网站的功能或运行
      效果，或者将用于上述用途的网站、方法进行运营或向公众传播，无论这些行为是否为
      商业目的；
      <br>（6）通过非轻松元宇开发、授权的第三方网站、插件、外挂、系统，登录或使用本网
      站和/或本服务，或制作、发布、传播上述工具；
      <br>（7）自行、授权他人或利用第三方网站对本网站和/或本服务及其组件、模块、数据等
      进行干扰；
      <br>（8）其他可能影响、干扰本网站和/或本服务正常运行的行为。
      <br>6.1.3数据获取使用
      <br>为了保护数据安全，您不得从事包括但不限于以下行为，也不得为其提供便利：
      <br>（1）未经其他用户明确同意，或在未向其他用户如实披露数据用途、使用范围等相关
      信息的情形下收集、复制、存储、使用或传输其他用户数据，侵害其他用户合法权益；
      <br>（2）将其他用户的帐号、昵称等个人信息用于任何未经用户及轻松元宇授权的用途；
      <br>（3）企图进行反射查找、跟踪、关联、挖掘、获取用户帐号、手机号和出生日期等个
      人信息；
      <br>（4）通过各种程序、网站等抓取任何用户的信息或与本网站和/或本服务相关的任何信
      息、数据；
      <br>（5）未经轻松元宇授权使用任何第三方网站、插件、外挂、系统等查看、获取本网站
      和/或本服务中所包含的轻松元宇、轻松元宇合作伙伴或用户的任何相关信息、数据等
      内容。
      <br>（6）其他危害数据安全的行为。
      <br>6.2【法律责任】
      <br>6.2.1您理解并同意：若您违反法律法规、本协议和/或轻松元宇其他协议、规则的，
      轻松元宇有权随时单方根据相应情形采取以下一项或多项措施（具体措施以及采取措施
      的时间长短由轻松元宇根据您的违法、违约情节相应情况确定）：
      <br>（1）对您进行警告；
      <br>（2）删除违法违规信息；
      <br>（3）限制您使用本网站和/或本服务部分或全部功能；
      <br>（4）中止、终止您对相应服务帐号的使用(简称“封号”)；
      <br>（5）采取其他合理、合法的措施；
      <br>（6）依法移交有关行政管理机关给予行政处罚，或者依法向有关机关报案追究刑事责
      任。
      <br>6.2.2如果您违反法律法规、本协议和/或轻松元宇其他协议、规则，轻松元宇对您或
      您使用的本网站和/或本服务采取任何行为或措施后，导致您暂时或永久无法使用服务
      帐号，您应自行承担相应后果（包括但不限于通信中断、用户资料及相关数据的清空或
      无法使用等）。由此造成您损失的，您应自行承担。
      <br>6.2.3您导致任何第三方损害的，您应当独立承担责任；轻松元宇因此遭受损失的，您
      也应当一并赔偿。
      <br>6.3【对自己行为负责】
      您充分了解并同意，您必须为自己使用本网站和/或本服务的一切行为负责，包括您所
      发表的任何内容以及由此产生的任何后果。您应对使用本网站和/或本服务时接触到的
      内容自行加以判断，包括因对内容的正确性、完整性或实用性的依赖而产生的风险，并
      承担由此引起的相应风险。轻松元宇在法律允许的范围内不对您因前述风险而导致的任
      何损失或损害承担责任。
      <br>七、【知识产权】
      <br>7.1轻松元宇是本网站的知识产权权利人。本网站的著作权、商标权、专利权、商业秘
      密等知识产权，以及与本网站相关的所有信息内容（包括但不限于文字、图片、音频、
      视频、图表、界面设计、版面框架、有关数据或电子文档等）均受法律法规和相应的国
      际条约保护，轻松元宇依法享有上述相关知识产权，但相关权利人依照法律规定应享有
      的权利除外。
      <br>7.2您理解并同意，数字藏品的知识产权由发行方或其他权利人拥有。上述权利并不因
      您购买数字藏品的行为而发生任何转移或共享。除另行取得拥有数字藏品知识产权的权
      利人书面同意外，您不得将数字藏品用于任何商业用途。
      未经轻松元宇或相关权利人书面同意，您不得为任何商业或非商业目的自行或许可任何
      第三方实施、利用、转让上述知识产权。
      <br>7.3您承诺对您通过本网站所上传的内容（包括但不限于文字、图片、视频、音频及/
      或视频、音频中的音乐作品、声音等）拥有完整著作权或已获得合法授权，未侵犯任何
      第三方之合法权益。
      <br>7.4如您发现本网站及相关网站内，或数字藏品存在任何侵犯您权利的内容，请立即通
      过邮箱relaverse@126.com通知轻松元宇公司，提供您有关权利的初步证据，轻松元
      宇会根据法律法规要求及时处理您的投诉。
      <br>八、【第三方网站或技术】
      <br>8.1本网站可能会使用第三方网站或技术（包括本网站可能使用的开源代码和公共领域
      代码等，下同），这种使用已经获得合法授权。
      <br>8.2本网站如果使用了第三方的网站或技术，轻松元宇将按照相关法规或约定，对相关
      的协议或其他文件，可能通过本协议附件、在本网站安装包特定文件夹中打包等形式进
      行展示，它们可能会以“网站使用许可协议”“授权协议”“开源代码许可证”或其他
      形式来表达。
      <br> 8.3前述相关协议或其他文件均是本协议不可分割的组成部分，与本协议具有同等的法
      律效力，您应当遵守这些要求。如果您没有遵守这些要求，该第三方或者国家机关可能
      会对您提起诉讼、罚款或采取其他制裁措施，并要求轻松元宇给予协助，您应当自行承
      担法律责任。
      <br>九、【不可抗力及其他免责事由】
      <br>9.1您理解并同意，在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服
      务受到影响。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影
      响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件
      如战争、动乱、政府行为等。出现上述情况时，轻松元宇将努力在第一时间与相关单位
      配合，争取及时进行处理，但是由此给您造成的损失，轻松元宇在法律允许的范围内免
      责。
      <br>9.2在法律允许的范围内，轻松元宇对以下情形导致的服务中断或受阻不承担责任：
      <br>（1）受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏。
      <br>（2）用户或轻松元宇的电脑网站、系统、硬件和通信线路出现故障。
      <br>（3）用户操作不当或用户通过非轻松元宇授权的方式使用本服务。
      <br>（4）程序版本过时、设备的老化和/或其兼容性问题。
      <br>（5）其他轻松元宇无法控制或合理预见的情形。
      <br>9.3轻松元宇将会尽其商业上的合理努力保障您在本服务中的数据存储安全，但是，轻
      松元宇并不能就此提供完全保证，包括但不限于以下情形：
      <br>（1）轻松元宇不对由于非轻松元宇原因造成的您在本网站和/或本服务中相关数据的删
      除或储存失败负责。
      <br>（2）您应自行备份存储在本网站和/或本服务中的数据、信息或与本网站和/或本服务
      相关的数据、信息，双方另有约定的按相应约定执行。
      <br>（3）如果您停止使用本网站和/或本服务，或因您违反法律法规或本协议而被取消或终
      止使用本服务，轻松元宇有权从服务器上永久地删除您的数据。您的服务停止、终止或
      取消后，轻松元宇没有义务向您返还任何数据。
      <br>9.4您理解并同意，在使用本服务的过程中，可能会遇到网络信息或其他用户行为带来
      的风险，轻松元宇不对任何信息的真实性、适用性、合法性承担责任，也不对因侵权行
      为给您造成的损害负责。这些风险包括但不限于：
      <br>（1）来自他人匿名或冒名的含有威胁、诽谤、令人反感或非法内容的信息。
      <br>（2）遭受他人误导、欺骗或其他导致或可能导致的任何心理、生理上的伤害以及经济
      上的损失。
      <br>（3）其他因网络信息或用户行为引起的风险。
      <br>9.5您注意勿在使用本网站和/或本服务中透露自己的各类财产账户、银行卡、信用卡、
      第三方支付账户及对应密码等重要资料，否则由此带来的任何损失由您自行承担。您亦
      不应将其他个人信息通过本平台发表、上传或扩散。
      <br>9.6您理解并同意：为了向您提供有效的服务，本网站会利用您终端设备的处理器和带
      宽等资源。本网站使用过程中可能产生数据流量的费用，用户需自行向运营商了解相关
      资费信息，并自行承担相关费用。
      <br>9.7基于用户体验、本网站或相关服务的运营安全、规则要求及平台健康发展等综合因
      素，轻松元宇有权选择提供服务的对象，决定功能设置，决定功能开放、数据接口和相
      关数据披露的对象和范围。针对以下情形，轻松元宇有权视具体情况中止或终止提供相
      关服务，包括但不限于：
      <br>（1）违反法律法规或本协议的；
      <br>（2）影响服务体验的；
      <br>（3）存在安全隐患的；
      <br>（4）违背本网站或本服务运营原则、规范，或不符合轻松元宇其他管理要求的。
      <br>十、【其他】
      <br>10.1轻松元宇有权在必要时修改本协议条款。您可以在相关服务页面查阅最新版本的
      协议条款。本协议条款变更后，如果您继续使用轻松元宇提供的网站或服务，即视为您
      已接受变更后的协议。
      <br>10.2本协议签订地为中华人民共和国广东省深圳市南山区。
      <br> 10.3本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法
      律（不包括冲突法）。
      <br>10.4若您和轻松元宇之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，
      您同意将纠纷或争议提交本协议签订地（即广东省深圳市南山区）有管辖权的人民法院
      管辖。
      <br>10.5本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义
      解释的依据。
      <br>10.6本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方
      具有约束力。
    </div>
    <div class="f-17 bold text-center m-t-25">轻松小镇网站用户协议</div>
    <div>
      轻松小镇网站是由深圳市轻松元宇科技有限公司（以下简称“我们”或“轻松元宇”）
      研发和运营软硬件结合、专注“健康”的数字社交空间。在您使用此产品、服务的过程
      中，我们会按照如下方式收集您在使用服务时主动提供的或因为使用服务而产生的信息，
      用以向您提供服务、优化我们的服务以及保障您的账户安全：
      <br>（1）当您注册账号时，我们会收集您的手机号码，以便帮助您完成账号注册，保护您
      的账号安全，同时会向您的手机号码发送服务通知。在您购买数字藏品之前，我们会收
      集您的姓名、身份证件信息。手机号码属于敏感信息，收集此类信息是为了满足相关法
      律法规的网络实名制要求。若您不提供这类信息，您可能无法正常使用我们的服务。
      <br>（2）为了完成账号注册，我们会收集您为账号设置的头像、昵称以及您主动提供的
      其他资料信息。为了更好地向您提供本服务，轻松元宇可对上述昵称、头像以及在您本
      网站中的相关操作信息进行使用，并向您本人或其他用户展示该等信息。
      <br>（3）为保 障您正常使用我们的服务，维护我们服务的正常运行，改善及优化您的服务体验并保障
      您的账号安全，我们会收集您的常用设备标识（例如IMSI、IMEI、AndroidID等）、设
      备Mac地址、设备SN、手机型号、已安装应用列表、系统版本、硬件参数、网络状态
      及记录、系统日志、多媒体文件等信息。
      <br>（4）我们会保存您购买数字藏品的相关记录，并在应用中向您展示。在您主动同意
      公开购买记录后，其他用户可以进行访问。
      <br>（5）如您编辑头像、上传图片功能时，我们会在征得您的同意后，访问您的相册权
      限。上述信息属于敏感信息，如您拒绝提供该信息，将无法正常使用头像上传、编辑的
      相应服务。
      <br>（6）所有权限均不会默认开启，只有经过您明确同意后方视为获得授权。我们获得具
      体某一项权限并不代表我们必然会收集您的相关信息；即使我们已经获得敏感权限，也
      仅在必要时、根据本协议来收集、使用您的相关信息。您可以通过系统设置内关于本产
      品的应用权限设置，查看您上述个人信息的访问权限开启状态，并可以决定开启或关闭
      这些权限。开启这些权限即代表您授权我们可以收集和使用这些个人信息来实现上述的
      功能，关闭这些权限即代表您取消了这些授权，则我们将不再继续收集和使用您的这些
      个人信息，也无法为您提供上述与这些授权所对应的功能。您关闭权限的决定不会影响
      此前基于您的授权所进行的个人信息的处理。
      <br>（7）另外，根据相关法律法规及国家标准，以下情形中，我们可能会收集您的相关个
      人信息无需征求您的授权同意： 我们在以下情况下收集和使用您的个人信息无需取得
      您的同意：
      <br>①为订立、履行您作为一方当事人的合同所必需，或者按照依法制定的劳动规章制度和
      依法签订的集体合同实施人力资源管理所必需；
      <br>②为履行法定职责或者法定义务所必需；
      <br>③为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必
      需；
      <br>④为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理您的个人信息；
      <br>⑤依照《个人信息保护法》规定在合理范围内处理您自行公开或者其他已经合法公开的
      个人信息；
      <br>⑥法律、行政法规规定的其他情形。
      <br>（8）请您理解，我们向您提供的功能和服务是不断更新和发展的，如果某一功能或服
      务未在前述说明中且收集了您的信息，我们会通过页面提示、交互流程、网站公告等方
      式另行向您说明信息收集的内容、范围和目的，以征得您的同意。 请您注意，目前我
      们不会主动从轻松元宇以外的第三方获取您的个人信息。如未来为业务发展需要从第三
      方间接获取您的个人信息，我们会在获取前向您告知个人信息的来源、类型及使用范围，
      如我们开展业务需进行的个人信息处理活动超出您原本向第三方提供个人信息时的授
      权同意范围，我们将在处理您该等个人信息前征得您的授权同意；此外，我们也将会严
      格遵守相关法律法规的规定，并要求第三方保障其提供的信息的合法性
      <br>（9）信息的存储
      <br>①信息存储的方式和期限
      我们会通过安全的方式存储您的信息，包括本地存储（例如利用APP进行数据缓存）、
      数据库和服务器日志。一般情况下，我们只会在为实现服务目的所必需的时间内或法律
      法规规定的条件下存储您的个人信息。
      <br>②信息存储的地域
      我们会按照法律法规规定，将境内收集的用户个人信息存储于中国境内。目前我们不会
      跨境传输或存储您的个人信息。将来如需跨境传输或存储的，我们会向您告知信息出境
      的目的、接收方、安全保证措施和安全风险，并征得您的同意。
      <br>③产品或服务停止运营时的通知 当我们的产品或服务发生停止运营的情况时，我们将
      以推送通知、公告等形式通知您，并在合理期限内删除您的个人信息或进行匿名化处理，
      法律法规另有规定的除外。
      <br>（10）信息安全
      <br>①安全保护措施
      <br>我们努力为用户的信息安全提供保障，以防止信息的泄露、丢失、不当使用、未经授权
      访问和披露等。我们使用多方位的安全保护措施，以确保用户的个人信息保护处于合理
      的安全水平，包括技术保护手段、管理制度控制、安全体系保障等诸多方面。
      <br>我们采用业界领先的技术保护措施。我们使用的技术手段包括但不限于防火墙、加密（例
      如SSL）、去标识化或匿名化处理、访问控制措施等。此外，在取得您同意的情况下，
      我们还会不断加强安装在您设备端的网站的安全能力。例如，我们会在您的设备本地完
      成部分信息加密工作，以巩固安全传输；我们会了解您设备安装的应用信息和运行的进
      程信息，以预防病毒、木马等恶意程序。
      <br>我们建立了保障个人信息安全专门的管理制度、流程和组织。例如，我们严格限制访问
      信息的人员范围，要求他们遵守保密义务并进行审计，违反义务的人员会根据规定进行
      处罚。我们也会审查该管理制度、流程和组织，以防未经授权的人员擅自访问、使用或
      披露用户的信息。 ·我们建议您在使用产品和服务时充分注意对个人信息的保护，我们
      也会提供多种安全功能来协助您保护自己的个人信息安全。
      <br>②安全事件处置措施
      <br>若发生个人信息泄露、损毁、丢失等安全事件，我们会启动应急预案，阻止安全事件扩
      大。安全事件发生后，我们会及时以推送通知、邮件等形式告知您安全事件的基本情况、
      我们即将或已经采取的处置措施和补救措施，以及我们对您的应对建议。如果难以实现
      逐一告知，我们将通过公告等方式发布警示。
      <br>③我们如何使用信息
      <br>我们严格遵守法律法规的规定以及与用户的约定，按照本协议所述使用收集的信息，以
      向您提供更为优质的服务。
      <br>（11）信息使用规则
      <br>我们会按照如下规则使用收集的信息：
      <br>①我们会根据我们收集的信息向您提供各项功能与服务；
      <br>②我们会根据您使用本产品的频率和情况、故障信息、性能信息等分析我们产品的运行
      情况，以确保服务的安全性，并优化我们的产品，提高我们的服务质量。
      <br>③我们不会将我们存储在分析网站中的信息与您提供的个人身份信息相结合。
      <br>（12）告知变动目的后征得同意的方式
      我们将会在本协议所涵盖的用途内使用收集的信息。如我们使用您的个人信息，超出了
      与收集时所声称的目的及具有直接或合理关联的范围，我们将在使用您的个人信息前，
      再次向您告知并征得您的明示同意。
      <br>（13）对外提供
      目前，除我们主动列明的情形外，我们不会主动共享或转让您的个人信息至第三方。我
      们会审慎选择第三方和第三方服务，督促相关第三方在按照本协议或另行与您达成的约
      定收集和使用您的个人信息，并采取适当的安全技术和管理措施保障您的个人信息安全。
      如存在其他共享或转让您的个人信息或您需要我们将您的个人信息共享或转让至第三
      方情形时，我们会直接或确认第三方征得您对上述行为的明示同意。
      我们不会对外公开披露所收集的个人信息，如必须公开披露时，我们会向您告知此次公
      开披露的目的、披露信息的类型及可能涉及的敏感信息，并征得您的明示同意。
      随着我们业务的持续发展，我们有可能进行合并、收购、资产转让等交易，我们将告知
      您相关情形，按照法律法规及不低于本协议所要求的标准继续保护或要求新的控制者继
      续保护您的个人信息。
      <br>另外，根据相关法律法规及国家标准，以下情形中，我们可能会共享、转让、公开披露
      个人信息无需事先征得您的授权同意：
      <br>a)与国家安全、国防安全直接相关的；
      <br>b)与公共安全、公共卫生、重大公共利益直接相关的；
      <br>c)与犯罪侦查、起诉、审判和判决执行等直接相关的；
      <br>d)出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同
      意的；
      <br>e)个人信息主体自行向社会公众公开个人信息的；
      <br>f)从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。
      <br>（14）您的权利
      <br>我们可能会视产品具体情况为您提供相应的操作设置，目前您无法查询更改相关个人信
      息，我们后续将上线相关服务以便您可以查询、复制、删除、更正或撤回您的相关个人
      信息，届时您可通过“个人中心”页面具体协议进行操作。我们会不断优化您行使权利
      的渠道、体验，如您有任何疑问或需求，可以通过本协议约定的方式与我们联系。
      当您决定未来不再使用我们的产品或服务时，未来可在个人中心内申请注销账号。注销
      账号后，除法律法规另有规定外，我们将删除或匿名化处理您的个人信息。
      <br>（15）变更
      <br>我们可能会适时对本协议进行修订。当本协议的条款发生变更时，我们会按照法律法规
      要求，以适当方式向您提示变更后的本协议，并向您说明生效日期。请您仔细阅读变更
      后的本协议内容，您继续使用轻松小镇表示您同意我们按照更新后的本协议处理您的个
      人信息。
      <br>（16）未成年人保护
      <br>我们非常重视对未成年人个人信息的保护。我们会根据相关法律法规的规定，要求未成
      年人在使用轻松小镇的服务或向我们提供个人信息前，应当事先取得自己的监护人（比
      如自己的父母）的授权同意。我们仅在法律法规允许、未成年人的监护人明确同意或者
      有必要保护未成年人的情况下使用、对外提供或公开披露该信息。 若您是未成年人的
      监护人，请您关注您监护的未成年人是否是在取得您的授权同意之后使用轻松小镇的服
      务。如果您对您所监护的未成年人的个人信息有疑问，请通过第17节中的联系方式与我
      们联系。
      <br>（17）联系我们
      <br>我们设立了专门的个人信息保护团队和个人信息保护负责人，如果您对本隐私政策或个
      人信息保护相关事宜有任何疑问或投诉、建议时，您可以将问题发送至
      relaverse@126.com与我们联系。我们将尽快审核所涉问题，并在15日内或法律法规
      规定的期限内予以反馈。
    </div>
  </div>
</template>

<script>
import Back from '../components/Back'
export default {
  name: 'Protocol',
  components: { Back },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background-color:#ffffff')
  },
}
</script>

<style scoped lang="scss">
.protocol {
  position: relative;
  padding-top: 50px;
}
</style>
