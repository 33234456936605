<template>
  <div class="p-30 protocol">
    <back></back>
    <div class="f-17 bold text-center">轻松小镇虚拟商品购买服务协议</div>
    <div>
      《轻松小镇虚拟商品购买服务协议》（以下简称“本协议”）由您与轻松小镇虚拟商品提供方共同缔结，本协议具有合同效力。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制轻松小镇责任的条款（以下称“免责条款”）、对用户权利进行限制的条款（以下称“限制条款”）、约定争议解决方式和司法管辖的条款，以及开通或使用某项服务的单独协议。前述免责、限制及争议解决方式和司法管辖条款可能以黑体加粗、颜色标记或其他合理方式提示您注意，包括但不限于本协议第二条、第三条、第四条、第六条、第九条等相关条款，请您在确认同意本协议之前或在购买轻松小镇虚拟商品服务之前再次阅读前述条款。
      请您详细阅读本协议所有条款，如您同意本协议，您可继续购买轻松小镇虚拟商品。如果您对本协议或轻松小镇虚拟商品有意见或建议，可与轻松小镇客服联系。您点击同意、接受或下一步，或您注册、使用轻松小镇虚拟商品均视为您已阅读并同意签署本协议。
      <br>
      一、【定义】<br>
      1.1 本协议：指本协议正文、《轻松小镇用户协议》和《轻松小镇隐私协议》及其修订版本。上述内容一经正式发布，即为本协议不可分割的组成部分。<br>
      1.2 虚拟商品规则：指轻松小镇虚拟商品提供方不时发布并修订的关于轻松小镇虚拟商品的发售规则、使用公告、兑换说明、提示及通知等内容。<br>
      1.3 虚拟商品提供方：指向您提供轻松小镇虚拟商品及相关服务的深圳市轻松元宇科技有限公司，在本协议中简称为“轻松小镇”。<br>
      1.4 虚拟商品：指由轻松小镇负责运营的虚拟商品的统称，其可以以文字、图形化或其他数字形式进行表现，主要包括创世种子、普通种子、转售符等相关内容及附带的服务与玩法。<br>
      1.5 您：又称“用户”，指被授权使用轻松小镇虚拟商品及其服务的自然人。<br>
      1.6 虚拟商品数据：指您在使用轻松小镇虚拟商品过程中产生的被服务器记录的各种数据，包括但不限于虚拟商品数据、行为日志、购买日志等等数据。<br>

      二、【用户账号】<br>
      2.1 您如果需要使用和享受轻松小镇虚拟商品，则您需要将您享有使用权的轻松小镇账用于相关虚拟商品购买和使用流程，并按照国家法律法规的相关要求，登录实名注册系统并进行实名注册。同时，使用该账号时，其行为应符合轻松小镇不时修订并公布的《轻松小镇用户协议》和《轻松小镇隐私协议》和其他有关前述账号注册、使用的规范。
      您进行实名注册时，应提供有关您本人真实、合法、准确、有效的身份信息及其他相关信息，且不得以他人身份资料进行实名注册。否则，轻松小镇有权采取本协议第5.5条第二款规定的一种或多种处理措施，并可公布处理结果，因此造成的一切后果由您自行承担，且轻松小镇有权要求您赔偿因此给轻松小镇造成的所有损失。
      如您之前已在轻松小镇的中完成实名，您使用同一账号注册、登录轻松小镇虚拟商品时，无需再提供相关身份信息。<br>
      2.2 您充分理解并同意，轻松小镇会按照国家相关要求核验您的实名注册信息（包括相关实名认证及年龄情况等），即轻松小镇会根据您的实名注册信息判断您是否年满18周岁、您提交的实名身份信息是否规范或实名验证是否通过等，从而决定是否对您的购买账号予以相关限制。
      <br>2.3您充分理解并同意，轻松小镇有权根据法律法规要求校验用户注册所提供的身份信息是否真实、有效，并应积极地采取技术与管理等合理措施保障用户账号的安全、有效；用户有义务妥善保管其账号和验证登陆信息，并正确、安全地使用其账号及验证登录信息。任何一方未尽上述义务导致账号、验证登陆信息遗失、账号被盗等情形而给用户和他人的民事权利造成损害的，应当承担由此产生的法律责任。
      若您发现有他人冒用或盗用您的账号及密码、或任何其他未经您合法授权使用的情形时，应立即通知轻松小镇客服。您通知轻松小镇时，应提供与您注册身份信息相一致的个人有效身份信息，轻松小镇收到您的有效请求并核实身份后，会结合具体情况采取相应措施（包括但不限于暂停该账号的登录和使用等），轻松小镇因根据您的请求采取相应措施而造成您及其他用户损失的，由您自行承担。若您没有提供有效身份信息或您提供的个人有效身份信息与所注册的身份信息不一致的，轻松小镇有权拒绝您的请求，因此造成您损失的，由您自行承担。
      <br>2.4 您充分理解并同意，为高效利用服务器资源，如果您3年内未使用该账号，轻松小镇有权在提前通知的情况下，对该账号及其账号下的虚拟商品数据及相关信息采取删除等处置措施。
      <br>2.6 在轻松小镇提供账号注销功能情形下，如您需要注销使用账号的，可按照轻松小镇官方提供的账号注销指引进行操作，并应保证满足轻松小镇官方公布的有关账号注销的相关条件，同时同意轻松小镇官方公布的号注销协议和其他有关规则。
      同时也特别提示您， 如您注销账号，该账号下的虚拟商品虚拟道具及其他增值服务（为描述方便，以下也统称“虚拟商品收益”）也将会被清除。据此，在您注销使用账号前请您确保已妥善处理该账号下的虚拟商品收益或相关的虚拟商品收益已结清。一旦您注销使用账号，如届时该账号下还存在虚拟商品收益的（包括但不限于在虚拟商品使用期间已产生但尚未失效或未使用的虚拟商品及其他虚拟商品增值服务，及未来可能产生的虚拟商品收益），视为您自愿放弃该等虚拟商品收益，轻松小镇有权对该账号下的全部虚拟商品收益做清除处理，因此产生的后果由您自行承担。前述虚拟商品收益包括但不限于：虚拟商品等级；与虚拟商品成长升级相关的所有数据（包括但不限于生长值、水滴、荣誉值、勋章等）；尚未使用的虚拟商品（如创世种子、普通种子及其他等）；已经购买但未到期或未使用完的其他增值服务；已产生但未消耗完毕的其他虚拟商品收益或未来预期的虚拟商品收益等。
      <br>三、【用户信息收集、使用及保护】
      <br>3.1 您同意并授权轻松小镇为履行本协议之目的收集您的个人信息。在您购买或使用轻松小镇虚拟商品的过程中，轻松小镇会按照《轻松小镇隐私协议》规定的方式收集您在使用服务时主动提供的或因为使用服务而产生的信息，用以向您提供、优化我们的服务以及保障您的账号安全。
      <br>3.2 如轻松小镇向您推送关于轻松小镇的活动信息、推广信息等各类信息的，对您个人信息的收集、使用将遵循《轻松小镇隐私协议》。
      <br>3.3 您知悉并同意，如国家有权机关依法要求轻松小镇协助调查您轻松小镇账号（包括实名身份信息）及虚拟商品相关数据（如消费、转赠等数据）等信息的，轻松小镇可将前述相关信息提供给国家有权机关。
      <br>3.4 您应对通过轻松小镇虚拟商品及相关服务了解、接收或可接触到的包括但不限于其他用户在内的任何人的个人信息予以充分尊重，您不应以搜集、复制、存储、传播或以其他任何方式使用其他用户的个人信息，否则，由此产生的后果由您自行承担。
      <br>3.5 保护用户个人信息及隐私是轻松小镇的一项基本原则。轻松小镇对用户个人信息收集、使用及保护等将遵循《轻松小镇隐私协议》等协议规定。如您已同意轻松小镇依据前述协议或规定处理您个人信息又撤回同意的，根据法律规定，你撤回同意，不影响撤回前基于您同意已进行的个人信息处理活动的效力。

      <br>四、【虚拟商品】
      <br>4.1 在您遵守本协议及相关法律法规的前提下，轻松小镇给予您一项个人的、不可转让及非排他性的许可，以购买、使用轻松小镇虚拟商品及相关服务。您仅可为非商业目的使用轻松小镇虚拟商品及相关服务。
      <br>4.2 本条及本协议其他条款未明示授权的其他一切权利仍由轻松小镇保留，您在行使这些权利时须另外取得轻松小镇的书面许可。
      <br>4.3 如果您违反本协议任何约定的，轻松小镇有权采取本协议第5.5条第二款规定的一种或多种处理措施，并可公布处理结果，且（/或）有权要求您赔偿因您违约行为而给轻松小镇造成的所有损失。
      <br>4.4 您充分理解并同意，轻松小镇有权依合理判断对违反有关法律法规或本协议规定的行为进行处理，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，用户应独自承担由此而产生的一切法律责任。
      <br>4.5 您充分理解并同意，因您违反本协议或相关服务条款的规定，导致或产生第三方主张的任何索赔、要求或损失，您应当独立承担责任；轻松小镇因此遭受损失的，您也应当一并赔偿。
      <br>4.6 您充分理解并同意：虚拟商品及相关服务等均是轻松小镇虚拟商品的一部分，轻松小镇在此许可您依本协议而获得其使用权。您购买、使用虚拟商品虚拟道具服务等应遵循本协议的要求。同时，创始种子等虚拟商品及增值服务等售出后将不可退货，同时可能受到一定有效期限的限制，即使您在规定的有效期内未使用，除不可抗力或可归责于轻松小镇的原因外，一旦有效期届满，将会自动失效。如相关虚拟商品及增值服务没有标明使用期限，或者标明的使用期限为“永久”或其他具有与“永久”相同或类似意思的内容的（如“无限期”、“无限制”等），则其使用期限为自您获得该虚拟商品及增值服务之日起至该虚拟商品终止运营之日止。
      您充分理解并同意：为更好地向用户提供虚拟商品，轻松小镇有权对虚拟商品相关内容（包括但不限于虚拟商品名称、内容、权益、限制、设计、性能及相关数值设置等）作出调整、更新或优化。
      <br>4.7 您充分理解并同意：为保障您虚拟商品账号安全，为营造公平、健康及安全的虚拟商品购买环境，在您购买、使用轻松小镇虚拟商品及增值服务的过程中，在不违反相关法律规定情况下，轻松小镇可以通过技术手段了解您终端设备的随机存储内存以及与轻松小镇同时运行的相关程序。一经发现有任何未经授权的、危害轻松小镇虚拟商品正常运营的相关程序，轻松小镇可以采取合理措施予以打击。
      <br>4.8 轻松小镇向用户提供虚拟商品本身属于商业行为，用户有权自主决定是否根据轻松小镇自行确定的收费项目（包括但不限于购买虚拟商品使用权以及其他服务等各类收费项目）及收费标准支付相应的费用，以获得相应的虚拟商品。如您不按相应标准支付相应费用的，您将无法获得相应的虚拟商品。
      <br>您知悉并同意：收费项目或收费标准的改变、调整是一种正常的商业行为，您不得因为收费项目或收费标准的改变、调整而要求轻松小镇进行赔偿或补偿。
      <br>您知悉并同意，您使用法定货币购买或兑换虚拟商品及其他服务的，您所购买或兑换的虚拟商品及其他服务将不能退还或兑换成法定货币，但法律另有强制性规定的除外。
      <br>您知悉并同意，如您购买或兑换虚拟商品及其他服务的交易因退款等原因被取消的，轻松小镇有权就该被取消的交易对应下发到您轻松小镇账号中的虚拟商品及增值服务进行扣除。如您轻松小镇账号中的虚拟商品虚及增值服务不足以扣除的，就不足部分，轻松小镇可在您轻松小镇账号中的虚拟商品及增值服务后续增加时优先扣除。
      <br>4.9 在任何情况下，轻松小镇不对因不可抗力导致的您在购买或使用轻松小镇虚拟商品及增值服务过程中遭受的损失承担责任。该等不可抗力事件包括但不限于国家法律、法规、政策及国家机关的命令及其他政府行为或者其它的诸如地震、水灾、雪灾、火灾、海啸、台风、罢工、战争等不可预测、不可避免且不可克服的事件。
      <br>4.10 轻松小镇虚拟商品可能因软件BUG、版本更新缺陷、第三方病毒攻击或其他任何因素导致您的虚拟商品及增值服务等数据发生异常。在数据异常的原因未得到查明前，轻松小镇有权暂时冻结该轻松小镇账号；若查明数据异常为非正常购买或使用行为所致，轻松小镇有权恢复轻松小镇账号数据至异常发生前的原始状态（包括向第三方追回被转移数据），而无须向您承担任何责任。若数据异常是由于您实施违法违规行为或违反本协议约定的行为所致，轻松小镇有权采取本协议第5.5条第二款规定的一种或多种处理措施。
      <br>您理解并同意，如虚拟商品部署了多个服务器的，在虚拟商品运营过程中，为了提高虚拟商品的可玩性和互动性，创造更好的虚拟商品环境，轻松小镇可结合虚拟商品器内活跃用户人数等情况，适时调整服务器数量，将其中多个服务器之间的用户临时或永久地合并或迁移到同一个服务器中。
      <br>4.11 未经轻松小镇许可，您不得擅自与其他用户进行虚拟商品及增值服务的交易，或从第三方通过购买、接受赠与或者其他方式获得虚拟商品及增值服务。轻松小镇不对第三方交易行为（第三方交易，是指您从第三方通过购买、接受赠与或者其他的方式获得虚拟商品及增值服务的行为）负责，并且不受理因任何第三方交易发生纠纷而带来的申诉。
      <br>4.12 您充分理解到：不同操作系统之间存在不互通的客观情况，该客观情况并非轻松小镇造成，由此可能导致您在某一操作系统中的虚拟商品及增值服务数据不能顺利转移到另一操作系统中。由于您在不同系统进行切换造成的损失和虚拟商品数据及增值服务丢失风险应由您自行承担，轻松小镇对此不承担任何责任。
      <br>4.13 轻松小镇自行决定终止轻松小镇虚拟商品及增值服务运营时或轻松小镇虚拟商品及增值服务因其他任何原因终止运营时，轻松小镇会按照国家有关终止运营的相关法律法规规定处理虚拟商品及增值服务终止运营相关事宜，以保障用户合法权益。

      <br>五、【用户行为规范】
      <br>5.1 您充分了解并同意，您必须为自己轻松小镇账号下的一切行为负责，包括您所发表的任何内容以及由此产生的任何后果。
      <br>5.2 您除了可以按照本协议的约定购买、使用轻松小镇虚拟商品及增值服务之外，不得进行任何侵犯轻松小镇虚拟商品的知识产权的行为，或者进行其他的有损于轻松小镇或其他第三方合法权益的行为。
      <br>5.3 您在购买、使用轻松小镇虚拟商品及增值服务时须遵守法律法规，不得购买、使用轻松小镇虚拟商品及增值服务从事违法违规行为，包括但不限于以下行为：
      <br>（一）违反宪法确定的基本原则的；
      <br>（二）危害国家统一、主权和领土完整的；
      <br>（三）泄露国家秘密、危害国家安全或者损害国家荣誉和利益的；
      <br>（四）煽动民族仇恨、民族歧视，破坏民族团结，或者侵害民族风俗、习惯的；
      <br>（五）宣扬邪教、迷信的；
      <br>（六）散布谣言，扰乱社会秩序，破坏社会稳定的；
      <br>（七）宣扬淫秽、色情、赌博、暴力，或者教唆犯罪的；
      <br>（八）侮辱、诽谤他人，侵害他人合法权益的；
      <br>（九）违背社会公德的；
      <br>（十）有法律、行政法规和国家规定禁止的其他内容的。
      <br>5.4 除非法律允许或轻松小镇书面许可，您不得从事下列行为：
      <br>（1）删除轻松小镇虚拟商品及其增值服务上关于著作权的信息；
      <br>（2）通过非轻松小镇开发、授权的第三方软件、插件、外挂、系统，以购买、使用轻松小镇虚拟商品及增值服务；
      <br>（3）对轻松小镇拥有知识产权的虚拟商品及增值服务内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；
      <br>（4）建立有关轻松小镇虚拟商品及增值服务的镜像站点，或者进行网页（络）快照，或者利用架设服务器等方式，为他人提供与轻松小镇虚拟商品及增值服务完全相同或者类似的服务；
      <br>（5）使用、修改或遮盖轻松小镇虚拟商品及其服务的名称、商标或其它知识产权；
      <br>（6）其他未经轻松小镇明示授权的行为。
      <br>5.5 您在购买、使用轻松小镇虚拟商品及增值服务过程中如有以下任何行为（以下也称“违法违规行为”）之一的，轻松小镇有权视情节严重程度，依据本协议及相关用户协议的规定，对您采取本条第二款规定的一种或多种处理措施，并可公告处理结果：
      <br>（1）以任何方式破坏轻松小镇虚拟商品或影响轻松小镇虚拟商品的正常进行，包括但不限于：
      <br>1）违规及（或）恶意购买、使用虚拟商品及增值服务的行为，包括但不限于恶意批量注册轻松小镇账号，以及以破坏服务器鉴权、恶意挤服等方式购买、使用虚拟商品及增值服务行为；
      <br>2）恶意实施可能导致轻松小镇服务器宕机、卡顿，或导致其他用户掉线、延迟等无法正常虚拟商品的行为，如利用DDOS对轻松小镇服务器或其他玩家进行网络流量攻击的行为等；
      <br>3)利用轻松小镇程序漏洞实施获益或给其他玩家造成不良影响的行为；
      <br>4）购买或使用虚拟商品及增值服务数据表现异常，影响轻松小镇公平的其他行为。
      <br>（2）传播非法言论或不当信息；
      <br>（3）盗取他人轻松小镇账号、虚拟商品及增值服务；
      <br>（4）进行轻松小镇账号交易、账号共享等影响账号安全的行为；
      <br>（5）未经轻松小镇许可，擅自与其他用户进行虚拟商品及增值服务等交易，或从第三方通过购买、接受赠与或者其他式获得虚拟商品及增值服务；
      <br>（6）在未经轻松小镇授权或认可的折扣渠道进行折扣虚拟商品道具交易（例如在非轻松小镇官方渠道低价购买折扣种子）；
      <br>（7）以某种方式暗示或伪称轻松小镇内部员工或某种特殊身份，企图得到不正当利益或影响其他用户权益的行为；
      <br>（8）违反本协议任何约定，或违反国家法律法规任何规定的行为；
      <br>（9）其他在行业内被广泛认可的不当行为，或通过虚拟商品相关协议或规则（包括但不限于用户协议、小镇公告、购买规则、提示及通知）公布的其他违规行为。
      <br>前款规定的处理措施包括但不限于：
      <br>（1）警告；及/或
      <br>（2）减少或限制您的虚拟商品收益，该虚拟商品收益包括但不限于虚拟商品及增值服务成长升级相关的所有数据（如生长值、水滴、荣誉值、勋章等）的提升以及虚拟商品及增值服务的获取；及/或
      <br>（3）删除或注销轻松小镇的账号、虚拟商品及增值服务数据或其他相关信息；及/或
      <br>（4）限制或禁止您进入使用虚拟商品及增值服务；及/或
      <br>（5）暂时或永久性地禁止您的账号登录登录轻松小镇（以下称“封号”）；及/或
      <br>（6）暂时或永久性地禁止您违法违规行为涉及的特定终端（包括但不限于PC终端、移动终端等）登录轻松小镇；及/或
      <br>（7）暂停或终止您使用轻松小镇虚拟商品及相关服务，或单方通知您终止本协议；及/或
      <br>（8）就您的违法违规行为提起相应民事诉讼，追究您的侵权、违约或其他民事责任，并要求您赔偿轻松元宇公司因您违法违规行为所受到的损失（包括轻松元宇公司所受到的直接经济损失、名誉或商誉损失以及轻松元宇公司对外支付的赔偿金、和解费用、律师费用、诉讼费用及其他间接损失），或移交有关行政管理机关给予行政处罚，或者移交司法机关追究您的刑事责任；及/或
      <br>（9）轻松小镇通过虚拟商品相关协议或规则（包括但不限于用户协议、小镇公告、购买规则、提示及通知）公布的其他处理措施。
      <br>您知悉并同意，由于外挂、机器人购买等作弊购买行为具有隐蔽性或用完后即消失等特点，轻松小镇有权根据您的购买数据和表现异常判断您有无非法或作弊购买行为。
      <br>5.6 您知悉并同意，如轻松小镇依据本协议对您的轻松小镇账号采取封号处理措施的，具体封号期间由轻松小镇根据您违规行为情节而定。
      <br>您知悉并同意：（1）在封号期间，您轻松小镇账号中的虚拟商品及增值服务都将无法使用；（2）如前述虚拟商品及增值服务存在一定有效期，该有效期可能会在封号期间过期，您轻松小镇账号解封后，您将无法使用该等已过期的虚拟商品及增值服务。
      <br>六、【知识产权】
      <br>6.1 轻松小镇是轻松小镇虚拟商品及增值服务的知识产权权利人。轻松小镇虚拟商品及增值服务（包括但不限于轻松小镇虚拟商品及增值服务的一切著作权、商标权、专利权、商业秘密等知识产权及其他合法权益，以及与轻松小镇虚拟商品及增值服务相关的所有信息内容（包括文字、图片、音频、视频、图表、界面设计、版面框架、有关数据或电子文档等）均受中华人民共和国法律法规和相应的国际条约保护，轻松小镇享有上述知识产权和合法权益，但相关权利人依照法律规定应享有的权利除外。未经轻松小镇事先书面同意，您不得以任何方式将轻松小镇虚拟商品及增值服务进行商业性使用或通过信息网络传播轻松小镇虚拟商品及增值服务内容。
      <br>6.2 您在购买、使用虚拟商品及增值服务中产生的所有权和知识产权归轻松小镇所有，轻松小镇有权保存、处置该虚拟商品数据，但国家法律法规另有规定的从其规定。
      <br>轻松小镇对其合法处理虚拟商品数据形成的相关数据产品和服务享有法律法规规定的财产权益。
      <br>6.3 轻松小镇虚拟商品及增值服务可能涉及第三方知识产权，而该等第三方对您基于本协议在轻松小镇中使用该等知识产权有要求的，轻松小镇将以适当方式向您告知该要求，您应当一并遵守。
      <br>七、【遵守当地法律监管】
      <br>7.1 您在购买、使用虚拟商品及增值服务过程中应当遵守当地相关的法律法规，并尊重当地的道德和风俗习惯。如果您的行为违反了当地法律法规或道德风俗，您应当为此独立承担责任。
      <br>7.2 您应避免因购买、使用虚拟商品及增值服务而使轻松小镇卷入政治和公共事件，否则轻松小镇有权暂停或终止对您的服务。
      <br>八、【管辖与法律适用】
      <br>8.1 本协议签订地为中华人民共和国广东省深圳市南山区。
      <br>8.2 本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。
      <br>8.3 您和轻松小镇之间因本协议发生的（和/或与本协议有关的）任何纠纷或争议，首先友好协商解决；协商不成的，您同意将纠纷或争议提交至本协议签订地有管辖权的人民法院管辖。
      <br>8.4 本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。
      <br>8.5 本协议条款无论因何种原因部分无效，其余条款仍有效，对各方具有约束力。
      <br>九、【其他】
      <br>9.1 轻松小镇有权在必要时变更本协议条款，您可以在轻松小镇虚拟商品及增值服务的相关页面查阅最新版本的协议条款。本协议条款变更后，如果您继续购买、使用轻松小镇虚拟商品及增值服务，即视为您已接受变更后的协议。
      深圳市轻松元宇有限公司
    </div>
  </div>
</template>

<script>
import Back from '../components/Back'
export default {
  name: 'Protocol',
  components: { Back },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background-color:#ffffff')
  }
}
</script>

<style scoped lang="scss">
.protocol{
  position: relative;
  padding-top: 50px;
}
</style>
